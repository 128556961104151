import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthenticationService, UserService } from '../services';
import { User } from '../models/user';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    items!: MenuItem[];
    user: any;
    metadata = {};
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, private auth: AuthService, @Inject(DOCUMENT) private doc: Document, private userService : UserService, private router : Router) {

        this.auth.user$.subscribe(user => {
            this.user = user;
            this.items = [
                {
                    label: this.user.email,
                    icon: 'pi pi-fw pi-user'
                },
                {
                    separator: true
                },
                {
                    label: 'Change Password',
                    icon: 'pi pi-fw pi-unlock',
                    command: () => this.navigateToChangePassword()
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-fw pi-sign-out',
                    command: () => this.logOut()
                },
            ]
        });
    }

    ngOnInit() {
        // this.userService.getUserRole();
    }

    logOut() {
        this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin + '/#/landing'}});
    }

    navigateToChangePassword() {
        this.router.navigate(['/change-password']);
    }

    userSettings(): void {
    }
}
