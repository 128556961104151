import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { EnvironmentInjector, ɵɵinject } from '@angular/core';
import { UserService } from '../services';
import { map } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
export const roleGuard: CanActivateFn = (route, state) => {
  const allowedRoles = route.data.roles as Array<string>;
  const jwtHelper: JwtHelperService = new JwtHelperService();

  const authService = ɵɵinject(AuthService);
  const router = ɵɵinject(Router);

  return authService.getAccessTokenSilently().pipe(
    map(token => {
      var hasRole = false;
      var _decodedToken = jwtHelper.decodeToken(token);
      var _userRole = _decodedToken.UserRoles as Array<string>;

      var hasAccess = allowedRoles.filter(role => _userRole.includes(role)).length > 0;
      if (hasAccess) {
        hasRole = true;
      }
      else {
        console.log('access denied!');
      //  router.navigateByUrl('')
      }
      return hasRole;
    })
  )
};
