<hr class="solid">
<ng-template #activatingTemplate>
   <app-loader-spinner></app-loader-spinner>
</ng-template>

<ng-container *ngIf="!activating; else activatingTemplate">
    <div class="container">
       <form [formGroup]="activationForm" (ngSubmit)="activateCards()">
        <div class="grid">
            <div class="field col-12 md:col-6" >
                <label for="firstExternalCardNumber" style="font-weight: bold;">External Card Number (First)</label>
                <input [autofocus]="true" type="text"pInputText  inputmode="numeric" formControlName="firstExternalCardNumber" type="number" [(ngModel)]="firstExternalCardNumber" placeholder="External Card Number (First)" class="w-full" styleClass="w-full" />
                <small class="p-error block" *ngIf="
                activationForm.get('firstExternalCardNumber')?.invalid && activationForm.get('firstExternalCardNumber')?.dirty">
                First External Card ID is required.</small>
            </div>
            <div class="field col-12 md:col-6">
                <label for="lastExternalCardNumber" style="font-weight: bold;">External Card Number (Last)</label>
                <input [autofocus]="true"  type="text" pInputText inputmode="numeric" formControlName="lastExternalCardNumber"   type="number" [(ngModel)]="lastExternalCardNumber" placeholder="External Card Number (Last)" class="w-full" styleClass="w-full"   />
                <small class="p-error block" *ngIf="
                activationForm.get('lastExternalCardNumber')?.invalid && activationForm.get('lastExternalCardNumber')?.dirty">
                Last External Card ID is required.</small>
            </div>
            <div class="field col-12 md:col-6">
                <label for="lowSeqNumber" style="font-weight: bold;" >Sequence Number (Low)</label>
                <p-inputNumber formControlName="lowSeqNumber"  mode="decimal"  [useGrouping]="false"  [(ngModel)]="lowSeqNumber"  placeholder="Sequence Number (Low)" class="w-full" styleClass="w-full"  />
                <small class="p-error block" *ngIf="
                activationForm.get('lowSeqNumber')?.invalid && activationForm.get('lowSeqNumber')?.dirty">
                Low sequence number is required.</small>
            </div>
            <div class="field col-12 md:col-6">
                <label for="highSeqNumber" style="font-weight: bold;">Sequence Number (High)</label>
                <p-inputNumber formControlName="highSeqNumber"   mode="decimal"  [useGrouping]="false" [(ngModel)]="highSeqNumber"   placeholder="Sequence Number (High)" class="w-full" styleClass="w-full"  />
                <small class="p-error block" *ngIf="
                activationForm.get('highSeqNumber')?.invalid && activationForm.get('highSeqNumber')?.dirty">
                High sequence number is required.</small>
            </div>
            <div class="field col-12 md:col-6">
                <label  for="numberOfCards" style="font-weight: bold;">Number of Cards</label>
                <p-inputNumber   formControlName="numberOfCards" mode="decimal"  [useGrouping]="false" [(ngModel)]="cardCount" placeholder="Number of Cards" class="w-full" styleClass="w-full"  (onInput)="totalAmount()"/>
                <small class="p-error block" *ngIf="
                activationForm.get('numberOfCards')?.invalid && activationForm.get('numberOfCards')?.dirty">
                Number of cards are required</small>
            </div>
            <div class="field col-12 md:col-6">
                <label for="loadAmount"  style="font-weight: bold;">Load amount</label>
                <p-inputNumber formControlName="loadAmount" [(ngModel)]="loadAmount" mode="decimal"  mode="currency" inputId="currency-germany"    currency="EUR"  locale="de-DE"   placeholder="Load amount"  (onInput)="totalAmount()" class="w-full" styleClass="w-full"/>
            </div>

            <div class="field col-12 md:col-6">
                <label style="font-weight: bold;">Total Amount</label>
                <p-inputNumber formControlName="totalLoadAmount" [disabled]="true"   mode="currency" inputId="currency-germany" currency="EUR" locale="de-DE" [(ngModel)]="totalLoadAmount"   class="w-full" styleClass="w-full" />

            </div>
            <div class="field col-12 md:col-6">
                <label style="font-weight: bold;">Notes</label>
                <input [autofocus]="true"  type="text" pInputText inputmode="numeric" formControlName="clientNote" [(ngModel)]="clientNote" placeholder="Note" class="w-full" styleClass="w-full"   />
            </div>
            <div class="field col-12 md:col-12">
                <small class="p-error block">{{this.apiErrorMessage}}</small>
            </div>


            <div class="field col-12 md:col-6" style="padding: 5px !important">
                <!-- <p-button class="flex align-items-center justify-content-center" label="Activate" (onClick)="activateCards()"></p-button> -->
                <p-button class="flex align-items-center justify-content-center" [disabled]="activationForm.invalid" label="Activate" type="submit"></p-button>

            </div>
            <div class="field col-12 md:col-6" style="padding: 5px !important">
                <p-button class="flex align-items-center justify-content-center" label="Cancel" (onClick)="cancel()" severity="secondary"
                  ></p-button>
            </div>
        </div>
       </form>
    </div>
</ng-container>
