<p-toolbar styleClass="mb-4">
    <ng-template pTemplate="left">
      <!-- <div class="card-container">
          <p class="p-0 m-0"><img src="../../../assets/layout/images/cards.png" alt=""></p>
          <span class="breadcrumbs" *ngIf="breadcrumbs.length > 0">
              <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first">
                <a *ngIf="first" [routerLink]="['..']" style="font-size: medium; font-weight: bold;" >{{ breadcrumb.label }}</a>
                <span
                  *ngIf="first"
                  style="font-size: medium; font-weight: bold">
                  &nbsp;/&nbsp;
                </span>
                <a *ngIf="!first && !last" [routerLink]="['../']" style="font-size: medium; font-weight: bold;" >{{ breadcrumb.label }}</a>
                <span
                  *ngIf="!first && !last"
                  style="font-size: medium; font-weight: bold">
                  &nbsp;/&nbsp;
                </span>
                <span *ngIf="last" style="font-size: medium; font-weight: bold;" >{{ breadcrumb.label }}</span>
              </ng-container>
            </span>
        </div> -->
    </ng-template>
    <ng-template pTemplate="right">
        <div class="buttons-container">
            <p-button label="Activate" styleClass="p-button-info" (onClick)="showModal('activateCards')"></p-button>
            <p-button label="Group Activation" styleClass="p-button-info" (onClick)="showModal('cardGroup')"></p-button>
             <p-button label="Load funds" styleClass="p-button-info" (onClick)="showModal('loadFunds')"></p-button>
                <!--<p-button label="Change status" styleClass="p-button-info" (onClick)="showModal('changeStatus')"></p-button>
            <p-button label="Create group" styleClass="p-button-info" (onClick)="showModal('createGroup')" *ngIf="createGroupVisible"></p-button>  -->
        </div>
    </ng-template>
</p-toolbar>


<!--
<p-dialog header="Activate Card" [(visible)]="activateVisible" [style]="{ width: '30vw' }" [modal]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Activate Card</span>
    </ng-template>
    <p>Select existing card to activate or add a new card. Loading money is optional.</p>
    <div>
        <div class="flex align-items-center gap-2 mb-6">
            <input pInputText id="email" class="flex-auto"  />
            <input pInputText id="email" class="flex-auto" />
        </div>
        <div class="flex align-items-center gap-2 mb-6">
            <p-button  label="Activate" styleClass="p-button-info flex-auto"></p-button>
            <p-button (onClick)="onActivationDialogClosed()" label="Cancel" styleClass="p-button-text flex-auto"></p-button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Header" [(visible)]="statusVisible" [style]="{ width: '25vw', height: '45%' }" [modal]="true" >
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Change status</span>
    </ng-template>
    <div class="status-modal-body">
        <div class="card flex justify-content-center">
                <input pInputText id="username" />
                <label for="username">Username</label>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="Save" styleClass="p-button-info"></p-button>
        <p-button (click)="statusVisible = false" label="Cancel" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog> -->
