import { Injectable, inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { AuthService } from '@auth0/auth0-angular';


@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    returnAuth: boolean;
    constructor(private router: Router, private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var t = this.auth.isAuthenticated$.subscribe(isAuthenticated => {
            this.returnAuth = isAuthenticated;
            if (isAuthenticated) {

            } else {
                this.router.navigateByUrl('/landing');
            }
        })
        return this.returnAuth;

    }
}