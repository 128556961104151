<hr class="solid">

<ng-template #activatingTemplate>
    <app-loader-spinner></app-loader-spinner>
</ng-template>
<ng-container *ngIf="!activating; else activatingTemplate">
    <div class="container">
            <form [formGroup]="activationForm" (submit)="loadFunds()">
                <div class="grid">
                    <div class="field col-12 md:col-6" >
                        <label for="externalCardId" style="font-weight: bold;">External Card ID*</label>
                        <input type="text" pInputText inputmode="numeric" formControlName="externalCardId" type="number" [(ngModel)]="externalCardId" placeholder="External Card ID" class="w-full" styleClass="w-full" />
                        <small class="p-error block" *ngIf="
                        activationForm.get('externalCardId')?.invalid && activationForm.get('externalCardId')?.dirty">
                        External Card ID required.</small>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="loadAmount" style="font-weight: bold;">Load Amount</label>
                        <input type="text" pInputText formControlName="loadAmount" inputmode="numeric"  type="number" [(ngModel)]="loadAmount" placeholder="Load Amount" class="w-full" styleClass="w-full"   />
                        <!-- <small class="p-error block" *ngIf="
                        activationForm.get('loadAmount')?.invalid && activationForm.get('loadAMount')?.dirty">
                        Last External Card ID is required.</small> -->
                    </div>
                    <div class="field col-12 md:col-6" style="padding: 5px !important">
                        <!-- <p-button class="flex align-items-center justify-content-center" label="Activate" (onClick)="activateCards()"></p-button> -->
                        <p-button class="flex align-items-center justify-content-center" [disabled]="activationForm.invalid" label="Load Funds" type="submit"></p-button>
        
                    </div>
                    <div class="field col-12 md:col-6" style="padding: 5px !important">
                        <p-button class="flex align-items-center justify-content-center" label="Cancel" (onClick)="cancel()" severity="secondary"
                          ></p-button>
                    </div>
                </div>
            </form>
            <!-- <div class="field col-12 md:col-6">
                <h5>Card ID</h5>
                <input pInputText placeholder="Card ID" style="width: 100%;">
            </div>
            <div class="field col-12 md:col-6">
                <h5>Load amount</h5>
                <input pInputText placeholder="Load amount" style="width: 100%;">
            </div>
            <div class="field col-12 md:col-6" style="padding: 5px !important">
                <p-button class="flex align-items-center justify-content-center" label="Activate"></p-button>
            </div>
            <div class="field col-12 md:col-6" style="padding: 5px !important">
                <p-button class="flex align-items-center justify-content-center" label="Cancel" severity="secondary"
                  ></p-button>
            </div> -->
        </div>
</ng-container>

