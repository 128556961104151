import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UserRole } from '../enums/user-roles';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    public jwtHelper: JwtHelperService = new JwtHelperService();
    model: any[] = [];
    decodedToken: any;
    isLoading: boolean = true;
    constructor(public layoutService: LayoutService, private authService: AuthService) { }

    ngOnInit() {

        this.authService.getAccessTokenSilently().subscribe(token => {
            this.decodedToken = this.jwtHelper.decodeToken(token);
            this.model = [
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Home', icon: 'custom-home-icon', routerLink: ['/home'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Cards', icon: 'custom-cards-icon', routerLink: ['/cards'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Group Activation History', icon: '', routerLink: ['/group-activation/report'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.SupportUser, UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Transactions', icon: '', routerLink: ['/transactions/search'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Funding Accounts', icon: 'custom-wallet-icon', routerLink: ['/funding-accounts'] }
                    ]
                },
                {
                    visible: this.hasAccess([UserRole.ClientManager, UserRole.Administator]),
                    label: '',
                    items: [
                        { label: 'Programs', icon: 'custom-program-icon', routerLink: ['/programs'] }
                    ]
                },
                {
                  visible: this.hasAccess([UserRole.LedgerSystemReporting]),
                  label: '',
                  items: [
                    { label: 'Ledger', routerLink: ['/ledgers'] }
                  ]
                }
            ];
            this.isLoading = false;
        });

        this.authService.user$.subscribe(userToiken => { });
    }

    hasAccess(accessRole: Array<string>): boolean {
        var hasRole = false;
        var _role = this.decodedToken.UserRoles as Array<string>;
        return accessRole.filter(role => _role.includes(role)).length > 0;

    }
}
