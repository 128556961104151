import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
  form!: FormGroup;
  submitted = false;
  isValidUser!: Boolean;
  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private auth: AuthService) {

  }
  ngOnInit() {
    var t = this.auth.isAuthenticated$.subscribe(test => {
    })
  }

  // get f() { return this.form.controls; }
  // get unAuthenticated() {return this.form.errors?.unauthenticated;}

  redirectSignIn() {
    this.auth.loginWithRedirect({
    }
    );

  }
}
