<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
        <span>SetldHub</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
    <p-avatar   icon="pi pi-user" shape="circle" size="large" (click)="avatarMenu.toggle($event)"></p-avatar>
        <p-tieredMenu [style]="{width:'350px'}" #avatarMenu [model]="items" [popup]="true" >

        </p-tieredMenu>
    </div>
</div>

<!-- <button #btn type="button" pButton label="Toggle" (click)="menu.toggle($event)"></button>
<p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu> -->
