export const environment = {
  title: 'Development',
  production: false,
  hermesApiUrl: 'https://test-hermes-api.setldpay.net/api',
  apiUrlALB: 'https://test-albapi.setldpay.net',
  auth: {
    domain: "test-auth.setldpay.net",
    clientId: "Lqif3N6lmMRi9nkNYSgmOG8aPdnFOCrR",
    authorizationParams: {
      audience: "https://hermes-api.com",
      redirect_uri: "https://test-hermes.setldpay.net",
    },
    httpInterceptor: {
      allowedList: [`https://test-hermes-api.setldpay.net/*`],
    },
  },
};
